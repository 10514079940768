import burgerImage from '../../assets/images/burger.png';
import HomeBannerImage from '../../assets/images/home-banner.png';
import HomeBannerMobileImage from '../../assets/images/home-banner-mobile.jpg';
import BaseContainer from '../../components/BaseContainer/BaseContainer';
import Image from '../../components/Image';
import { classNames, isSessionExist } from '../../utils';
import { Carousel, CarouselContent } from '../../components/Carousel/Carousel';
import { useHistory } from 'react-router-dom';
import { isArrayNotEmpty } from '../../helper';
import StoreCard from '../../components/StoreCard/StoreCard';
import React, { useEffect, useState } from 'react';
import ic_bulk_order from '../../assets/images/ic_bulk_order.svg';
import ic_single_order from '../../assets/images/ic_single_order.svg';
import ic_discount from '../../assets/images/ic_discount.svg';
import ic_bulk_order_selected from '../../assets/images/ic_bulk_order_selected.svg';
import ic_single_order_selected from '../../assets/images/ic_single_order_selected.svg';
import ic_discount_selected from '../../assets/images/ic_discount_selected.svg';
import DeliveryLocationPicker from '../../components/MapPicker/MapPicker';
import { useDispatch, useSelector } from 'react-redux';
import SearchProduct from '../../components/SearchProduct/SearchProduct';
import { listMarketingBanner, listProductsV2 } from '../../store/slices/customer.slice';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { isObjectNotEmpty } from '../../helper/object';
import { useMediaQuery } from '../../hooks/hooks';

const HomeV2 = (props) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { userApi, customerApi } = useSelector((state) => state);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const categoryList = [
    {
      icon: ic_bulk_order,
      iconSelected: ic_bulk_order_selected,
      text: 'Bulk Order',
    },
    {
      icon: ic_single_order,
      iconSelected: ic_single_order_selected,
      text: 'Single Order',
    },
    {
      icon: ic_discount,
      iconSelected: ic_discount_selected,
      text: 'Discount',
    },
  ];

  useEffect(() => {
    dispatch(listMarketingBanner());
  }, []);

  useEffect(() => {
    /* Session not exist, no need to wait for user payload */
    if (!isSessionExist()) {
      dispatch(
        listProductsV2({
          location: userApi.currentCoordinate, // location from map
          filterBy: getCategory(selectedCategory),
        }),
      );
      return;
    }

    /* Session exist, wait for user payload */
    if (userApi.payload) {
      dispatch(
        listProductsV2({
          location: userApi.deliverToAddress?.location ?? '', // location from user address
          filterBy: getCategory(selectedCategory),
        }),
      );
      return;
    }
    /* ignore effect */
  }, [userApi.deliverToAddress, userApi.currentCoordinate, selectedCategory]);

  const getCategory = (index) => {
    switch (index) {
      case 0:
        return 'BULK_ORDER';
      case 1:
        return 'SINGLE_ORDER';
      case 2:
        return 'DISCOUNT';
      default:
        return null;
    }
  };

  return (
    <>
      {/* Mobile header */}
      <section className="h-16 md:hidden">
        <img className="w-full h-full object-top object-cover md:hidden" alt="HomeBanner" src={HomeBannerMobileImage} />
      </section>

      {/* Location Selection */}
      {isMobile ? (
        <BaseContainer BaseContainer className="mt-3 md:hidden">
          <SectionHeader className="mb-3 md:mb-8" text={'Your Location'} />
          <DeliveryLocationPicker className="mb-4 md:mb-8" />
          <SearchProduct
            onSubmit={(data) => {
              history.push({ pathname: '/search', search: '?keyword=' + data.searchKeyword });
            }}
          />
        </BaseContainer>
      ) : (
        <BaseContainer BaseContainer className="hidden md:grid grid-cols-1 my-6 md:my-12 md:grid-cols-2">
          <div className="flex items-center lg:mx-12 xl:mx-20">
            <div className="flex flex-col items-start">
              <p className="text-3xl text-primary-500 font-medium mb-2 md:text-4xl">Welcome to Nubes</p>
              <p className="text-lg font-light mb-4">All the best restaurants with their top menu is waiting for you, they cant&apos;t wait for your order!</p>
              <div className="flex flex-col rounded-xl shadow-md px-8 py-4 border-2 border-gray-100 bg-white">
                <p className="font-medium mb-4 text-lg">Hi there! where can we bring your delicious meal today?</p>
                <DeliveryLocationPicker />
              </div>
            </div>
          </div>
          <div className="hidden flex-col items-center justify-center mx-20 md:flex">
            <Image className=" object-cover" src={burgerImage} alt="" />
          </div>
        </BaseContainer>
      )}

      {/* Desktop Banner */}
      <BaseContainer className="hidden justify-center mb-8 md:flex">
        <Image className="object-cover w-full h-auto rounded-2xl" src={HomeBannerImage} alt="" />
      </BaseContainer>

      {/*Filter by Category */}
      <BaseContainer className="pb-4 md:pb-8">
        <SectionHeader className="mb-3 md:mb-8" text={'Find by Category'} />
        <div className={`grid gap-x-5 grid-cols-${categoryList.length} md:gap-x-7`}>
          {isArrayNotEmpty(categoryList) &&
            categoryList.map((cat, index) => {
              return (
                <div key={index} className="flex justify-center">
                  <button
                    onClick={() => {
                      setSelectedCategory(index);
                    }}
                    className={classNames(
                      'max-w-[100px] md:max-w-full w-full text-black hover:text-primary-100 hover:bg-primary-400 flex flex-col items-center justify-center py-1 md:py-3 px-2 rounded-md border-solid border-2 hover:border-primary-400 animate-all duration-200',
                      selectedCategory != null && selectedCategory === index ? 'bg-primary-400 text-primary-100 border-primary-400' : '',
                    )}
                  >
                    {selectedCategory != null && selectedCategory === index
                      ? cat.iconSelected && <img alt="button-icon" className="my-1 h-4 w-auto md:h-5" src={cat.iconSelected} />
                      : cat.icon && <img alt="button-icon" className="my-1 h-4 w-auto md:h-5" src={cat.icon} />}
                    {cat.text && <div className="text-[10px] md:text-base font-medium my-1">{cat.text}</div>}
                  </button>
                </div>
              );
            })}
        </div>
      </BaseContainer>

      {/* Pre-Order Now */}
      <BaseContainer className="pb-4 md:pb-8">
        <SectionHeader className="mb-3 md:mb-8" text={'Pre-Order Now'} />
        <div className="flex justify-center">
          {isArrayNotEmpty(customerApi?.marketingList) && !customerApi.isRequestingMarketingList ? (
            <Carousel
              slides={customerApi?.marketingList.map((marketing, index) => {
                return (
                  <CarouselContent
                    key={index}
                    className="basis-full md:basis-2/4 lg:basis-2/5 mx-2"
                    onClick={() => {
                      if (marketing.link && !marketing.link.isEmpty) {
                        window.open(marketing.link, '_self');
                      }
                    }}
                  >
                    <img key={index} className="border-2 rounded-xl w-screen" alt="button-icon" src={marketing.banner} />
                  </CarouselContent>
                );
              })}
              options={{ align: 'start', dragFree: true, loop: true }}
              isAutoplay
            />
          ) : (
            <div className="my-4">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </BaseContainer>

      {/* Nubes Aspiration */}
      <BaseContainer className="pb-4 md:pb-8">
        <SectionHeader className="mb-3 md:mb-8" text={'Nubes Aspirations'} />

        {isArrayNotEmpty(customerApi?.productList) && !customerApi.isRequestingProductList ? (
          <div className="grid-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4">
            {customerApi?.productList.map((x, index) => (
              <StoreCard
                key={index}
                store={x}
                isTagVisible={isSessionExist() || isObjectNotEmpty(userApi.currentCoordinate)}
                isButtonVisible={!(isSessionExist() || isObjectNotEmpty(userApi.currentCoordinate))}
              />
            ))}
          </div>
        ) : (
          <div className="flex justify-center my-4">
            <LoadingSpinner />
          </div>
        )}
      </BaseContainer>
    </>
  );
};
const SectionHeader = ({ className, text }) => {
  return (
    <div className={classNames(className, 'flex w-full justify-start md:justify-center items-center gap-4')}>
      <span className="text-xl md:text-3xl font-bold">{text}</span>
      <div className="hidden md:block h-[2px] flex-1 bg-gray-300 md:mt-2"></div>
    </div>
  );
};

export default HomeV2;
