import React, { useCallback, useEffect } from 'react';
import { NextButton, PrevButton, usePrevNextButtons } from './CarouselArrow';
import useEmblaCarousel from 'embla-carousel-react';
import './carousel.css';
import { isArrayNotEmpty } from '../../helper';
import { classNames } from '../../utils';
import Autoplay from 'embla-carousel-autoplay';

function Carousel(props) {
  const {
    className,
    slides, // array of CarouselContent
    options, // https://www.embla-carousel.com/api/options/
    isSlideButtonVisible,
    slideButtonsClassName,
    isAutoplay,
    setApi,
  } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(options, isAutoplay ? [Autoplay()] : []);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    if (!emblaApi || !setApi) {
      return;
    }

    setApi(emblaApi);
  }, [emblaApi, setApi]);

  return (
    <section className={classNames('embla h-full', className)}>
      <div className="embla__viewport h-full" ref={emblaRef}>
        <div className="embla__container h-full">{isArrayNotEmpty(slides) && slides}</div>
      </div>
      {isSlideButtonVisible && (
        <div className="embla__controls">
          <div className={classNames('embla__buttons', slideButtonsClassName)}>
            <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
          </div>
        </div>
      )}
    </section>
  );
}

const CarouselContent = ({ children, className, onClick }) => {
  return (
    <div className={classNames('embla__slide grow-0 shrink-0', className)} onClick={onClick}>
      {children}
    </div>
  );
};

export { Carousel, CarouselContent };
