import { useEffect, useState } from 'react';
import { Form, Input } from '../Form';
import { Drawer } from 'antd';
import ChevronDown from '../../assets/images/ic-chevron-down.svg';
import Image from '../Image';

/**
 * @param {string} className
 * @param {boolean} showFilters - Set filters (sortBy,FilterBy) button visibility.
 * @param {String} initialSearch - Initial text for search input.
 * @param {Object} initialSort - Initial sort value based on supportedSelectionList
 * @param {Object} initialCategory - Initial category value based on supportedSelectionList
 * @param {Function} onSubmit - Callback function triggered on submit.
 * @param {Object} onSubmit.data - Data passed to the onSubmit function.
 */
const SearchProduct = ({ className, showFilters, initialSearch = '', initialSort, initialCategory, onSubmit = (data) => null }) => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const [activeDrawerContent, setActiveDrawerContent] = useState(null); // supportedSelectionList item
  const [searchKeyword, setSearchKeyword] = useState(initialSearch);
  const [selectionChange, setSelectionChange] = useState(false);
  const [activeSelection, setActiveSelection] = useState(
    new Map([
      initialSort
        ? initialSort
        : [
            'Sort by',
            {
              value: '',
              label: 'Sort by',
            },
          ],
      initialCategory ? initialCategory : ['Filter by', { value: '', label: 'Filter by' }],
    ]),
  );
  const supportedSelectionList = [
    {
      name: 'Sort by',
      options: [
        { value: 'SEARCH_RESULT', label: 'Search result' },
        { value: 'DISTANCE', label: 'Distance' },
        { value: 'RECOMMENDED', label: 'Recommended' },
        { value: 'RATING', label: 'Rating' },
        { value: 'POPULARITY', label: 'Popularity' },
      ],
    },
    {
      name: 'Filter by',
      options: [
        { value: 'BULK_ORDER', label: 'Bulk Order' },
        { value: 'SINGLE_ORDER', label: 'Single Order' },
        { value: 'DISCOUNT', label: 'Discount' },
      ],
    },
  ];

  useEffect(() => {
    if (!selectionChange) {
      return;
    }

    handleSubmit();
  }, [selectionChange, activeSelection]);

  const openDrawer = (selection) => {
    setActiveDrawerContent(selection);
    setDrawerVisibility(true);
  };

  const handleSubmit = () => {
    onSubmit({ searchKeyword: searchKeyword, activeSelection });
  };

  return (
    <div className={className}>
      <Form onSubmit={handleSubmit}>
        <Input.TextV2
          name="searchKeyword"
          className="mb-4"
          placeholder="Search for a product or restaurant"
          value={searchKeyword}
          retrieveValue={(e) => {
            setSearchKeyword(e.target.value);
          }}
          prefix={
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 mx-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a7 7 0 107 7 7 7 0 00-7-7zM21 21l-4.35-4.35" />
            </svg>
          }
        />
      </Form>
      {showFilters && (
        <div className="flex flex-row gap-4 overflow-x-auto scrollbar-hide">
          {supportedSelectionList.map((supportedSelection, index) => (
            <button
              key={index}
              type="button"
              className="scrollbar-hide w-full px-3 py-2 rounded-3xl text-sm text-gray-700 border border-gray-400 hover:bg-primary-100 hover:text-primary-600 hover:border-primary-400 focus:outline-none animate-all duration-200"
              onClick={() => {
                openDrawer(supportedSelection);
              }}
            >
              <div className="flex flex-row w-full justify-between">
                <p className="text-xs">{activeSelection && activeSelection.get(supportedSelection.name).label}</p>
                <Image className="w-2 h-auto mx-1" src={ChevronDown} alt="" />
              </div>
            </button>
          ))}
        </div>
      )}
      <Drawer
        placement="bottom"
        onClose={() => {
          setDrawerVisibility(false);
        }}
        open={drawerVisibility}
        closable={false}
      >
        {activeDrawerContent && (
          <div className="flex flex-col items-center">
            <div className="flex flex-col w-full max-w-md">
              <p className="text-2xl font-bold px-2">{activeDrawerContent.name}</p>
              <div className="w-full bg-gray-200 h-[1px] mt-2 mb-2 px-2" />
              {activeDrawerContent.options.map((option, index) => (
                <button
                  key={index}
                  className="group flex rounded-md w-full px-2 py-2 mb-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                  onClick={() => {
                    setDrawerVisibility(false);
                    setActiveSelection((prevSelection) => {
                      const updatedSelection = new Map(prevSelection);
                      updatedSelection.set(activeDrawerContent.name, option);
                      return updatedSelection;
                    });
                    setSelectionChange(true);
                  }}
                >
                  {option.label}
                </button>
              ))}
            </div>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default SearchProduct;
