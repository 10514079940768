import SearchProduct from '../../components/SearchProduct/SearchProduct';
import BaseContainer from '../../components/BaseContainer/BaseContainer';
import { isArrayNotEmpty } from '../../helper';
import DeliveryLocationPicker from '../../components/MapPicker/MapPicker';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useLocation } from 'react-router-dom';
import { isSessionExist } from '../../utils';
import { searchProduct } from '../../store/slices/customer.slice';
import StoreCard from '../../components/StoreCard/StoreCard';

const SearchPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userApi, customerApi } = useSelector((state) => state);
  const [searchParam, setSearchParam] = useState({
    search: new URLSearchParams(location.search).get('keyword') ?? '',
    sortBy: '',
    filterBy: '',
  });

  useEffect(() => {
    /* Session not exist, no need to wait for user payload */
    if (!isSessionExist()) {
      dispatch(
        searchProduct({
          location: userApi.currentCoordinate, // location from map
          search: searchParam?.search,
          sortBy: searchParam?.sortBy,
          filterBy: searchParam?.filterBy,
        }),
      );
      return;
    }

    /* Session exist, wait for user payload */
    if (userApi.payload) {
      dispatch(
        searchProduct({
          location: userApi?.deliverToAddress?.location ?? '', // location from user address
          search: searchParam?.search,
          sortBy: searchParam?.sortBy,
          filterBy: searchParam?.filterBy,
        }),
      );
      return;
    }
    /* ignore effect */
  }, [userApi.deliverToAddress, userApi.currentCoordinate, searchParam]);

  const onSearchSubmit = (data) => {
    setSearchParam({
      search: data.searchKeyword,
      sortBy: data?.activeSelection?.get('Sort by').value ?? '',
      filterBy: data?.activeSelection?.get('Filter by').value ?? '',
    });
  };

  return (
    <>
      <BaseContainer className="mt-4 mb-6 md:mt-6 md:mb-8">
        <SearchProduct className="mb-4" showFilters={true} onSubmit={onSearchSubmit} initialSearch={searchParam.search} />
        <DeliveryLocationPicker />
      </BaseContainer>

      <BaseContainer className="pb-4 md:pb-8">
        {isArrayNotEmpty(customerApi?.searchProductList) && !customerApi.isRequestingSearchProduct ? (
          <div className="grid-container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-4">
            {customerApi?.searchProductList.map((x, index) => (
              <StoreCard key={index} store={x} />
            ))}
          </div>
        ) : (
          <div className="flex justify-center my-4">
            <LoadingSpinner />
          </div>
        )}
      </BaseContainer>
    </>
  );
};

export default SearchPage;
