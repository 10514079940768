import React, { useEffect, useRef, useState } from 'react';
import { Drawer, Modal } from 'antd';
import { PinIcon, ShareIcon } from '@iconicicons/react';
import GoogleMapReact from 'google-map-react';
import { isSessionExist } from '../../utils';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Image from '../Image';
import IcChevronDown from '../../assets/images/ic-chevron-down.svg';
import IcPin from '../../assets/images/ic-pin.svg';
import { saveAddresses, saveCurrentCoordinate, saveDeliverToAddress, updateUser } from '../../store/slices/user.slice';
import _ from 'lodash';
import { showMapModal } from '../../store/slices/components.slice';

const DeliveryLocationPicker = ({ className }) => {
  return <div className={className}>{isSessionExist() ? <AddressPicker /> : <MapPicker />}</div>;
};

const AddressPicker = () => {
  const [drawerVisibility, setDrawerVisibility] = useState(false);
  const { userApi } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();

  const setDefaultAddress = (id) => {
    const newAddress = userApi.addresses.map((address, index) => {
      if (id === index) {
        return {
          ...address,
          isDefault: true,
        };
      } else {
        return {
          ...address,
          isDefault: false,
        };
      }
    });

    dispatch(updateUser({ addresses: newAddress }))
      .unwrap()
      .then(({ message }) => {
        const defaultAddress = _.find(newAddress, { isDefault: true });
        dispatch(saveDeliverToAddress(defaultAddress));
        dispatch(saveAddresses(newAddress));
      });
  };

  if (userApi?.deliverToAddress?.name) {
    return (
      <>
        <button
          type="button"
          className="scrollbar-hide flex-shrink-0 md:flex-shrink w-full px-2 py-2 rounded-md text-sm text-gray-700 border-2 border-gray-200 hover:bg-primary-100 hover:text-primary-600 hover:border-primary-400 focus:outline-none animate-all duration-200"
          onClick={() => {
            setDrawerVisibility(true);
          }}
        >
          <div className="flex flex-row w-full ">
            <Image className="w-2 h-auto mx-1" src={IcPin} alt="" />
            <p className="ml-1 text-xs basis-full text-left md:text-sm">{userApi?.deliverToAddress?.name}</p>
            <Image className="w-2 h-auto mx-1" src={IcChevronDown} alt="" />
          </div>
        </button>
        <Drawer
          placement="bottom"
          onClose={() => {
            setDrawerVisibility(false);
          }}
          open={drawerVisibility}
          closable={false}
        >
          <div className="flex flex-col items-center">
            <div className="flex flex-col w-full max-w-md">
              <p className="text-2xl font-bold px-2">Address List</p>
              <div className="w-full bg-gray-200 h-[1px] mt-2 mb-2 px-2" />
              {userApi?.addresses?.map((address, index) => (
                <button
                  key={index}
                  className="group flex rounded-md w-full px-2 py-2 mb-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none"
                  onClick={() => {
                    setDefaultAddress(index);
                    setDrawerVisibility(false);
                  }}
                >
                  {address.name}
                </button>
              ))}
              <div className="w-full bg-gray-200 h-[1px] mt-2 mb-2 px-2" />
              <button
                className="group flex rounded-md w-full px-2 py-2 mb-2 text-sm text-gray-700 hover:bg-primary-100 hover:text-primary-600 focus:outline-none items-center"
                onClick={() => {
                  history.push('/addresses');
                }}
              >
                <p>Manage addresses</p>
                <ShareIcon className="ml-2 h-4 w-4 flex-shrink-0" />
              </button>
            </div>
          </div>
        </Drawer>
      </>
    );
  }

  return (
    <button
      type="button"
      className="scrollbar-hide flex-shrink-0 md:flex-shrink w-full px-2 py-2 rounded-md text-sm text-gray-700 border-2 border-gray-200 hover:bg-primary-100 hover:text-primary-600 hover:border-primary-400 focus:outline-none animate-all duration-200"
      onClick={() => {
        history.push('/addresses');
      }}
    >
      <div className="flex flex-row w-full ">
        <Image className="w-2 h-auto mx-1" src={IcPin} alt="" />
        <p className="ml-1 text-xs basis-full text-left">Add new address</p>
        <ShareIcon className="pl-1 h-5 w-5 flex-shrink-0" />
      </div>
    </button>
  );
};

const MapPicker = () => {
  const { userApi } = useSelector((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalAddress, setModalAddress] = useState('');
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const mapNode = useRef();
  const { components } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (components.isMapModalOpen && isModalOpen === false) {
      showModal();
    }
  }, [components.isMapModalOpen]);

  const showModal = () => {
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by this browser.');
      return;
    }
    if (coordinates.lat || coordinates.lng) {
      // The modal might be re-opened and lat/lng are not null.
      // Prevent resetting the current coordinates.
      setIsModalOpen(true);
      return;
    }

    getCurrentCoordinate((coords) => {
      setIsModalOpen(true);
      setCoordinates({ lat: coords.latitude, lng: coords.longitude });
      geocode({ latitude: coords.latitude, longitude: coords.longitude });
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dispatch(showMapModal(false));
  };

  const selectLocation = () => {
    if (!modalAddress || !coordinates.lat || !coordinates.lng) {
      return;
    }
    dispatch(saveCurrentCoordinate({ address: modalAddress, lat: coordinates.lat, lon: coordinates.lon }));
    closeModal();
  };

  const getCurrentCoordinate = (onSuccess) => {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (permissionStatus.state === 'denied') {
        alert('Please allow location access.');
        return;
      }
      navigator.geolocation.getCurrentPosition(
        async ({ coords }) => {
          onSuccess(coords);
        },
        (error) => {
          alert('Failed to get your location');
          onSuccess({ latitude: 3.1319, longitude: 101.6841 }); // default location
        },
        { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true },
      );
    });
  };

  const resetMapPin = () => {
    getCurrentCoordinate((coords) => {
      setCoordinates({ lat: coords.latitude, lng: coords.longitude });
      geocode({ latitude: coords.latitude, longitude: coords.longitude });
    });
  };

  const geocode = ({ latitude, longitude }) => {
    if (!mapNode.current) {
      return;
    }
    new mapNode.current.maps_.Geocoder()
      .geocode({
        location: {
          lat: latitude,
          lng: longitude,
        },
      })
      .then(({ results }) => {
        setModalAddress(results[0].formatted_address);
      });
  };

  const onDragEnd = ({ center, coords, manual }) => {
    if (center) {
      geocode({ latitude: center.lat(), longitude: center.lng() });
      setCoordinates({ lat: center.lat(), lng: center.lng() });
    } else if (coords) {
      geocode({ latitude: coords.latitude, longitude: coords.longitude });
      setCoordinates({ lat: coords.latitude, lng: coords.longitude });
    } else if (manual) {
      geocode({ latitude: parseFloat(manual.lat) || null, longitude: parseFloat(manual.lon) || null });
      setCoordinates({ lat: parseFloat(manual.lat) || null, lng: parseFloat(manual.lon) || null });
    }
  };

  return (
    <>
      <div className="flex flex-row gap-x-2 items-center justify-center md:mb-2">
        <input
          className="w-full appearance-none border border-gray-400 rounded py-2 px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline disabled:bg-gray-100"
          id="Location"
          type="text"
          placeholder="Deliver to"
          value={userApi.currentCoordinate.address ?? ''}
          disabled={true}
        />
        <button className="rounded-lg shadow-sm p-2 bg-white hover:bg-primary-100 focus:bg-primary-100 animate-all duration-200" onClick={showModal}>
          <PinIcon className="flex-shrink-0 text-primary-500 w-6 h-6" />
        </button>
      </div>
      <Modal title={'Your location'} open={isModalOpen} onOk={selectLocation} onCancel={closeModal} width={1000} style={{ top: 20 }}>
        <div className="flex flex-col">
          <div className="flex flex-row gap-x-2 items-center justify-between">
            <p>
              <span className="font-bold">Select: </span>
              {modalAddress}
            </p>
            <button className="rounded-lg shadow-sm p-2 bg-white hover:bg-primary-100 focus:bg-primary-100 animate-all duration-200" onClick={resetMapPin}>
              <PinIcon className="flex-shrink-0 text-primary-500 w-6 h-6" />
            </button>
          </div>
          <div className={'mt-6 h-96 relative rounded-lg overflow-hidden ring-1 ring-black ring-opacity-5 w-full'}>
            {coordinates.lat && coordinates.lng && (
              <GoogleMapReact
                ref={mapNode}
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
                center={[coordinates.lat, coordinates.lng]}
                defaultZoom={17}
                onDragEnd={onDragEnd}
                yesIWantToUseGoogleMapApiInternals
              />
            )}
            <svg className="absolute top-[44.5%] left-[48%] w-8 h-8 text-primary-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd"></path>
            </svg>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeliveryLocationPicker;
